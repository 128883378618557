import React from 'react';
import img from '../images/chainxswap logo.jpg'; 
import img2 from '../images/incdex logo.jpg';
import img3 from '../images/xbridge logo.jpg';
import img4 from '../images/INDEX Exchange.png';

const Services = () => {

    return (
        <div id="services" className="black-bg bg-black-100 py-12" >
            <section data-aos="zoom-in-down">
                    <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl  uppercase font-bold header-text">Current InDex utilities</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-green-900'></div>
                        </div>
                    </div>

                    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-1 lg:grid-cols-3 gap-5">
                            
                            <div className="bg-black transition-all ease-in-out duration-400 border-2 border-white overflow-hidden text-white hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-4 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">ChainxSwap</h2>
                                    <p className="text-md font-medium">
                                       ChainxSwap integrates with over 80 blockchains, 220+ DEXs, and various bridges, allowing you to trade nearly all existing crypto assets from a single, intuitive interface. Our platform simplifies cross-chain trading, enabling seamless exchanges with just a few clicks and operating ten times faster than traditional methods.
                                    </p>
                                    <p className="text-md font-medium">
                                      With the addition of a fiat option, you can now effortlessly trade between fiat and crypto, making it easier than ever to enter and exit the crypto market. ChainxSwap ensures highly competitive swap fees, providing an efficient and cost-effective solution for all your trading needs.
                                        <br/><br/>
                                    </p>
                                    <a href="https://www.chainxswap.com/" className="text-white bg-green-900 hover:bg-green-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl  sm:mb-0">
                                    Launch
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </a>
                                </div>
                            </div>

                            <div className="bg-black transition-all ease-in-out duration-400 border-2 border-white overflow-hidden text-white hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-4 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img2} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">INCineratorDEX</h2>
                                    <p className="text-md font-medium">
                                        INCineratorDEX is a decentralized exchange (DEX) built on PulseChain, serving as an enhanced fork of UniSwap v3. <br/>It offers unique features such as token exchanges, liquidity aggregation, limit orders, and token transfers.<br/> Users can also add liquidity to pools, earn yield from providing liquidity, and participate in farming. Every transaction on INCineratorDEX contributes to the burning of $INC, supporting its value. <br/> The platform operates with two tokens: $BURN, the DEX token, and $EMBER, the farming reward token.
                                        <br/><br/><br/>                                   
                                    </p>
                                    <a href="https://www.incineratordex.com/" className="text-white bg-green-900 hover:bg-green-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl  sm:mb-0">
                                    Launch
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </a>
                                </div>
                            </div>

                            

                            <div className="bg-black transition-all ease-in-out duration-400 border-2 border-white overflow-hidden text-white hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-4 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img4} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Index Exchange</h2>
                                    <p className="text-md font-medium">
                                         InDex Exchange is a platform that offers a simple, fast, and secure way to exchange cryptocurrencies without requiring custody. It is designed for users who prioritize convenience and reliability, providing a seamless experience with every transaction. InDex Exchange operates with fixed rates, so users know exactly what they will receive, and all transactions are safeguarded with top-tier security measures. The platform guarantees reliable and smooth operations while maintaining complete anonymity, ensuring that users' personal data remains secure and confidential. With InDex Exchange, users can confidently manage their cryptocurrency exchanges with the assurance of privacy and protection.
                                    </p>
                                    <a href="https://in-dex.exchange/" className="text-white bg-green-900 hover:bg-green-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl  sm:mb-0">
                                    Launch
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </a>
                                </div>
                            </div>                    
                        </div>
                    </div>
            </section>

        </div>
    )
}

export default Services;