import React, { useState, useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import {useDocTitle} from '../components/CustomHook';
import axios from 'axios';
import NewsFeed from './NewsFeed';

const News = (props) => {

    useDocTitle('INDEX - Crypto News');



    return (
        <>
            <div>
                <NavBar />
            </div>
            <div id='demo' className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24">
                 <div>
      <h2>Crypto News</h2>
      <NewsFeed />
    </div>
            </div>
            <Footer />
        </>


    )
}

export default News;