import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { XMLParser } from 'fast-xml-parser';
import he from 'he';

const NewsFeed = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchFeed = async () => {
      try {
        const response = await axios.get('https://cointelegraph.com/rss');
        const xml = response.data;
        const parser = new XMLParser();
        const result = parser.parse(xml);

        // Debug log to inspect XML structure
        console.log(result);

        // Extract articles from the parsed result
        const items = result.rss.channel.item.map((item) => {
          // Check the structure for the image URL
          const mediaContent = item['media:content']?.url || '';
          const enclosure = item.enclosure?.url || '';
          const image = mediaContent || enclosure || '';

          console.log('Image URL:', image); // Debug log

          return {
            title: he.decode(item.title),
            link: item.link,
            description: he.decode(item.description),
            image: image,
          };
        });

        setArticles(items);
      } catch (error) {
        console.error('Error fetching RSS feed:', error);
      }
    };

    fetchFeed();
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      {articles.map((article, index) => (
        <div key={index} style={{ border: '1px solid #ddd', borderRadius: '8px', overflow: 'hidden', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
          <div style={{ padding: '15px' }}>
            <h3 style={{ margin: '0 0 10px', fontSize: '1.5rem' }}>
              <a href={article.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#333' }}>
                {article.title}
              </a>
            </h3>
            <div style={{ margin: '0', color: '#555' }} dangerouslySetInnerHTML={{ __html: article.description }} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default NewsFeed;
