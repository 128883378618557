import React from 'react';
import {Link} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import twitter from '../images/contacts/twitter.svg';
import telegram from '../images/contacts/telegram.svg';
import email from '../images/contacts/mail.svg';
import discord from '../images/contacts/discord.svg';
import youtube from '../images/contacts/youtube.svg';

const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer text-black-900 max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30">

                    {/* Top area: Blocks */}
                    <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11">

                    {/* 1st block */}
                    <div className="col-span-12 lg:col-span-4">
                        <div className="box-border border-b-4 border-green-900 p-8 bg-gray-200 text-gray-600 text-center rounded-lg xl:w-80 mx-auto">
                            <h3 className="font-bold text-4xl mb-4">INDEX</h3>
                            <div className='text-md font-medium text-gray-600'>
                                <h5>Advanced Cross-Chain Bridge & DEX Technology</h5>

                            </div>
                        </div>
                    </div>

                    {/* 2nd block */}
                    <div className="text-black-900 col-span-6 md:col-span-6 lg:col-span-1 ml-7 mx-auto">
                        <h6 className="text-green-900 text-xl font-bold mb-4">NAVIGATION</h6>
                        <ul className="text-md">
                        <li className="mb-2">
                            <HashLink to="#about" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">About</HashLink>
                        </li>
                        <li className="mb-2">
                            <HashLink to="#services" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Projects</HashLink>
                        </li>
                        <li className="mb-2">
                            <a href="https://docs.in-dex.xyz" target="_blank" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Docs</a>
                        </li>
                        <li className="mb-2">
                            <HashLink to="/contact" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Contact</HashLink>
                        </li>                            
                        </ul>
                    </div>

                    {/* 3rd block */}
                    <div className="text-black-900 col-span-6 md:col-span-6 lg:col-span-4 mx-auto">
                        <h6 className="text-green-900 text-xl font-bold mb-4">UTILITIES</h6>
                        <ul className="text-md">
                        <li className="mb-2">
                            <a href="https://www.chainxswap.com/" target="_blank" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Chainxswap</a>
                        </li>
                        <li className="mb-2">
                            <a href="https://www.incineratordex.com/" target="_blank"className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">INCineratorDEX</a>
                        </li>
                       
                        <li className="mb-2">
                            <a href="https://in-dex.exchange/" target="_blank" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">INDEX Exchange</a>
                        </li>
                        </ul>
                    </div>

                    {/* 4th block */}
                    <div className="col-span-12 text-center mx-auto lg:col-span-3 font-bold uppercase text-black-900">
                        <div className="text-xl mb-6">
                            Social Media Links.
                        </div>

                                <div className="text-md font-medium mb-6">
                                    Follow us on social media.
                                </div>
                        <div className="mx-auto text-center mt-2">
                                <ul className="flex justify-center mb-4 md:mb-0">
                                    <li>
                                       <a

                                        href="https://x.com/Chainxswap"
                                          rel="noopener"
                                          target="_blank"
                                        >
                                        <img src={twitter} width="22" height="20"/>
                                        </a>
                                    </li>
                                     <li>
                                       <a

                                        href="https://t.me/Chainxswap"
                                          rel="noopener"
                                          target="_blank"
                                        >
                                        <img src={telegram} width="22" height="20"/>
                                        </a>
                                    </li>
                                  <li>
                                       <a

                                        href="mailto:chainxswap@gmail.com"
                                          rel="noopener"
                                          target="_blank"
                                        >
                                        <img src={email} width="22" height="20"/>
                                        </a>
                                    </li>
                                    <li>
                                       <a

                                        href="https://discord.gg/zKUG4BEUyK"
                                          rel="noopener"
                                          target="_blank"
                                        >
                                        <img src={discord} width="22" height="20"/>
                                        </a>
                                    </li>
                                     <li>
                                       <a

                                        href="https://youtube.com/@In-DexTech"
                                          rel="noopener"
                                          target="_blank"
                                        >
                                        <img src={youtube} width="22" height="20"/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                    </div>          

                    </div>

                    <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
                <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
                    <div className="text-sm text-gray-200 font-semibold py-1">
                    Copyright &copy; {new Date().getFullYear()}{"  "}
                    <HashLink
                        to="#"
                        className=" hover:text-gray-900"
                    >
                        INDEX
                    </HashLink>. All rights reserved.
                    </div>
                </div>
                </div>

                </div>
                
            </footer>
        </>
    )
}
export default Footer;
