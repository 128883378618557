import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    return (
        <>
            <HashLink className="px-4 font-extrabold text-white hover:text-green-900" smooth to="/#about">
                About
            </HashLink>
            <HashLink className="px-4 font-extrabold text-white hover:text-green-900" smooth to="/#services">
                Projects
            </HashLink>
             <a href="https://docs.in-dex.xyz" className="px-4 font-extrabold text-white hover:text-green-900">
                Docs
            </a>
            <HashLink className="px-4 font-extrabold text-white hover:text-green-900" to="/contact#contact">
                Contact Us
            </HashLink>
           
        </>
    )
}

export default NavLinks;
